/*
*慢病管理-权限管理接口
*/
import request from '@/utils/request'

/*
	*********账号管理********
*/

//新增--角色列表
export function getRoles() {
  return request({
    url: `/admin/auth/role/roles`,
    method: 'get',
  })
}

//修改账号状态
export function adminStatus(id,status) {
  return request({
    url: `/admin/auth/admin/${id}/status`,
    method: 'put',
    data:{status}
  })
}

//管理员账号列表
export function adminList(params) {
  return request({
    url: `/admin/auth/admin`,
    method: 'get',
    params
  })
}

//渠道列表
export function agentList() {
  return request({
    url: `/admin/merchantList`,
    method: 'get',
  })
}



/*
	*********角色管理********
*/

//角色列表
export function roleList(params) {
  return request({
    url: `/admin/auth/role`,
    method: 'get',
    params
  })
}

//角色状态修改
export function roleStatus(id,data) {
  return request({
    url: `/admin/auth/role/${id}/status`,
    method: 'put',
    data
  })
}

//新增角色权限列表
export function roleAuthList(params) {
  return request({
    url: `/admin/auth/role/roleAuthList`,
    method: 'get',
    params
  })
}


/*
	*********权限管理********
*/

//权限管理列表
export function authList(params) {
  return request({
    url: '/admin/auth',
    method: 'get',
    params
  })
}

//删除权限
export function delAuth(id) {
  return request({
    url: `/admin/auth/${id}/auth`,
    method: 'delete',
  })
}

//新增权限
export function addAuth(data) {
  return request({
    url: `/admin/auth`,
    method: 'post',
	data
  })
}

//顶级权限列表
export function getTopAuth() {
  return request({
    url: `/admin/auth/getTopAuth`,
    method: 'get',
  })
}

//编辑权限
export function editAuth(id,data) {
  return request({
    url: `/admin/auth/${id}/auth`,
    method: 'put',
	data
  })
}



export function staffs(params) {
    return request({
      url: `/admin/getAgentStaffs`,
      method: 'get',
      params
    })
  }


